import { WeddingHotelWithAvailabilityAndRatesContract } from "@ttoengineering/delphi";
import Image from "next/image";
import ReactLinkify from "react-linkify";
import styled, { css } from "styled-components";
import { LocationIcon } from "../../../../../shared/atoms/Icons/LocationIcon/LocationIcon";

const CardSt = styled.div`
  font-family: Manrope;
  padding: 2rem;
  position: relative;
  margin: 0 -1rem;

  box-shadow: 0px 2px 25px 3px #76739a21;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    padding: 2rem;
    margin: 0 0 0 2rem;
  }`}
`;

const HeaderContainerSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }`}
`;

const HotelTagSt = styled.div`
  background: #e7f3ff;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  height: fit-content;
  width: fit-content;

  p {
    color: #3950d8;
    font-weight: 700;
    font-family: Manrope;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0.5rem 0;
    font-size: 1.25rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 0 1rem;
  }`}
`;

const DistanceToVenueTagSt = styled.div`
  // background: #e7ffec;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: fit-content;
  width: fit-content;

  p {
    color: #398d5a;
    font-weight: 700;
    font-family: Manrope;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
    font-size: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 0 1rem;
    margin-top: 0;
  }`}
`;

const HotelDescriptionSt = styled.p`
  font-family: Manrope;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  color: #2a2b3f;
`;

const ImagesContainerSt = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;

  ${({ theme }) => `${theme.breakpoints.md} {
    grid-template-columns: repeat(4, 1fr);
  }`}
`;

const ImageWrapperSt = styled.div`
  position: relative;
  height: 110px;
  width: 100%;
`;

export const HotelListCard = ({
  hotel,
}: {
  hotel: WeddingHotelWithAvailabilityAndRatesContract;
}) => {
  return (
    <CardSt>
      <HeaderContainerSt>
        <HotelTagSt>
          <p>{hotel.hotel.name}</p>
        </HotelTagSt>
        <DistanceToVenueTagSt>
          <LocationIcon />
          <p>{hotel.distanceFromVenueTag}</p>
        </DistanceToVenueTagSt>
      </HeaderContainerSt>

      <ReactLinkify>
        <HotelDescriptionSt>{hotel.hotel.description}</HotelDescriptionSt>
      </ReactLinkify>

      <ImagesContainerSt>
        {hotel.hotel.mainImage?.url && (
          <ImageWrapperSt>
            <Image
              layout="fill"
              objectFit="cover"
              src={hotel.hotel.mainImage?.url}
              alt="Hotel image"
            />
          </ImageWrapperSt>
        )}
        {hotel.hotel.images?.slice(0, 3).map((img) =>
          img.url ? (
            <ImageWrapperSt key={img.id}>
              <Image
                layout="fill"
                objectFit="cover"
                src={img?.url}
                alt="Hotel image"
              />
            </ImageWrapperSt>
          ) : (
            <></>
          )
        )}
      </ImagesContainerSt>
    </CardSt>
  );
};
