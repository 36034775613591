export const LockIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.17 4.93537V7.37218H10.4747V4.93537C10.4747 3.75025 9.81261 2.65481 8.73724 2.06229C7.66187 1.46973 6.3378 1.46973 5.26235 2.06229C4.18698 2.65485 3.5249 3.75021 3.5249 4.93537V7.37218H1.82959V4.93537C1.82959 3.17241 2.81501 1.54245 4.4147 0.661399C6.01438 -0.220466 7.98523 -0.220466 9.58492 0.661399C11.1846 1.54249 12.17 3.17247 12.17 4.93537H12.17Z"
        fill="#5F617D"
      />
      <path
        d="M12.7812 7.64282H1.21881C0.545383 7.6436 0.000809081 8.16498 0 8.80704V15.8567C0.00162086 16.498 0.546192 17.0178 1.21881 17.0186H12.7812C13.4538 17.0178 13.9992 16.498 14 15.8559V8.80627C13.9992 8.16421 13.4538 7.64359 12.7812 7.64282ZM8.18396 13.7231C8.21718 13.8592 8.18396 14.0023 8.09319 14.1114C8.00162 14.2212 7.86385 14.2847 7.71718 14.2847H6.2828C6.13612 14.2847 5.99836 14.2212 5.90679 14.1114C5.81603 14.0023 5.7828 13.8592 5.81603 13.7231L6.02995 12.8427H6.02914C5.57533 12.4575 5.41489 11.8464 5.62396 11.3026C5.83303 10.758 6.36869 10.3913 6.97567 10.3774H6.99998C7.61423 10.3766 8.16449 10.7402 8.38004 11.2887C8.59642 11.8379 8.43352 12.4567 7.97079 12.8428L8.18396 13.7231Z"
        fill="#5F617D"
      />
    </svg>
  );
};
