import {
  HotelCapsuleContract,
  RoomContract,
  RoomTypeContract,
  WeddingHotelWithAvailabilityAndRatesContract,
} from "@ttoengineering/delphi";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import {
  AmplitudeEventType,
  useAmplitudeContext,
} from "../../../../../../contexts/AmplitudeContext";
import { useCurrencyContext } from "../../../../../../contexts/CurrencyContext";
import { LockIcon } from "../../../../../shared/atoms/Icons/LockIcon/LockIcon";
import { posthog } from "posthog-js";
import { useSegment } from "../../../../../../contexts/SegmentContext";

const CardSt = styled.div<{ isSoldOut: boolean; number: number }>`
  font-family: Manrope;
  padding: 1rem 2rem 2rem;
  border: 1px solid #e2e6f0;
  position: relative;
  margin-left: 2rem;

  ::before {
    content: ${({ number }) => `"${number}"`};
    position: absolute;
    background-color: #e2e6f0;
    color: #404154;
    height: 1.85rem;
    width: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -1.85rem;
    top: -1px;
    z-index: 9;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  ${({ theme, isSoldOut }) => `${theme.breakpoints.md} {
    padding: 2rem 2rem 1rem;
    display: flex;
    justify-content: space-between;
    ${isSoldOut && `align-items: center;`}
  }`}
`;

const CoreInfoContainerSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    max-width: 24rem;
    margin-right: 2rem;
  }`}
`;

const CardTitleSt = styled.h3<{ isSoldOut: boolean }>`
  font-weight: 600;
  font-size: 1.21rem;
  line-height: 1.64rem;
  color: #2a2b3f;

  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      opacity: 0.7;
      text-decoration: line-through;
    `}

  ${({ theme }) => `${theme.breakpoints.md} {
    margin: 0;
  }`}
`;

const CardDescriptionSt = styled.p<{ isSoldOut: boolean }>`
  font-family: Manrope;
  font-weight: 500;
  font-size: 1rem;
  color: #7e7f93;
  line-height: 150%;

  ${({ isSoldOut }) =>
    isSoldOut &&
    css`
      opacity: 0.7;
    `}
`;

const ActionsContainerSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
    // justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    *:not(button) {
      text-align: right;
    }
  }`}
`;

const OrderSummaryContainerSt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ theme }) => `${theme.breakpoints.md} {
    flex-direction: column-reverse;
  }`}
`;

const PayLaterTextSt = styled.p`
  font-size: 1rem;
  color: #008009;
  font-weight: 800;
  margin: 1rem 0 0;

  ${({ theme }) => `${theme.breakpoints.md} {
    font-size: 0.78rem;
  }`}
`;

const FlexSt = styled.div``;

const NightsTextSt = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: #2a2b3f;
  margin: 0 0 1.3rem;

  ${({ theme }) => `${theme.breakpoints.md} {
    margin: 0 0 0.75rem;
  }`}
`;

const TotalSt = styled.p`
  font-weight: 600;
  font-size: 1.71rem;
  line-height: 2.35rem;
  color: #2a2b3f;
  margin: 0 0 1.1rem;

  ${({ theme }) => `${theme.breakpoints.md} {
    margin: 0;
  }`}
`;

const ReserveButtonSt = styled.button`
  width: 100%;
  background-color: #2a2b3f;
  color: white;
  padding: 0.9rem;
  font-weight: 600;
  font-size: 1rem;
  font-family: Manrope;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;

  :hover {
    cursor: pointer;
    background-color: #161621;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    max-width: 14rem;
  }`}
`;

const ButtonSubTextSt = styled.p<{ color: string; margin: string }>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  /* :nth-of-type(1) {
    margin: 1rem 0 0;
  } */

  /* :nth-of-type(2) {
    margin: 0;
  } */

  ${({ theme }) => `${theme.breakpoints.md} {
    font-size: 0.78rem;
  }`}
`;

const ReservedContainerSt = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 0.75rem;
  }
`;

const ReservedTextSt = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  color: #7e7f93;
`;

const PriceWrapperSt = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5rem;

  > span {
    height: fit-content;
    color: red;
    text-decoration: line-through;
  }
`;

export const RoomRateCard = ({
  isSoldOut,
  number,
  numberOfNights,
  room,
  roomType,
  hotelCapsule,
  hotel,
}: {
  isSoldOut: boolean;
  number: number;
  numberOfNights: number;
  room: RoomContract;
  roomType: RoomTypeContract;
  hotelCapsule: HotelCapsuleContract;
  hotel: WeddingHotelWithAvailabilityAndRatesContract;
}) => {
  const [priceExperimentVariant, setPriceExperimentVariant] = useState("");
  const router = useRouter();
  const { amplitudeLogEvent } = useAmplitudeContext();
  const segment = useSegment()
  const { formatPriceWithCurrency } = useCurrencyContext();
  const allowPayLater = useMemo(() => {
    const isPayLaterAvaible = !!(
      hotelCapsule.allowPayLater && roomType.freeCancellationUntil
    );
    if (!isPayLaterAvaible) return false;

    const today = new Date();
    const freeCancellationDeadline = new Date(roomType.freeCancellationUntil!);
    freeCancellationDeadline.setDate(freeCancellationDeadline.getDate() - 30);
    if (today >= freeCancellationDeadline) return false;

    return true;
  }, [hotelCapsule, roomType]);

  const onClickCallback = () => {
    segment.track('Rate Submitted');

    router.push(
      `/reservations/v2?hotelSlug=${hotel.hotel.slug}&hotelCapsuleSlug=${
        router.query.slug
      }&startDate=${hotelCapsule.startDate}&endDate=${
        hotelCapsule.endDate
      }&roomTypeId=${roomType.id}&nrRooms=${1}`
    );
  };

  useEffect(() => {
    posthog.onFeatureFlags((flags, flagVariants) => {
      if (posthog.getFeatureFlag("crossed-out-price") === "crossed-out-red") {
        setPriceExperimentVariant("crossed-out-red");
      } else {
        setPriceExperimentVariant("control");
      }
    });
  }, []);

  return (
    <CardSt number={number} isSoldOut={isSoldOut}>
      <CoreInfoContainerSt>
        <CardTitleSt isSoldOut={isSoldOut}>
          Guest room {number} - {roomType.name}
        </CardTitleSt>
        <CardDescriptionSt isSoldOut={isSoldOut}>
          {roomType.description}
        </CardDescriptionSt>
      </CoreInfoContainerSt>
      <ActionsContainerSt>
        {isSoldOut ? (
          <ReservedContainerSt>
            <LockIcon />
            <ReservedTextSt>Reserved by other guest</ReservedTextSt>
          </ReservedContainerSt>
        ) : (
          <>
            <OrderSummaryContainerSt>
              <NightsTextSt>Total for {numberOfNights} nights</NightsTextSt>
              <PriceWrapperSt>
                {priceExperimentVariant === "crossed-out-red" &&
                  roomType.nightlyOriginalBasePricePence * numberOfNights >
                    roomType.nightlyNegotiatedBasePricePence *
                      numberOfNights && (
                    <span>
                      {formatPriceWithCurrency(
                        roomType.nightlyOriginalBasePricePence * numberOfNights,
                        false
                      )}
                    </span>
                  )}
                <TotalSt>
                  {formatPriceWithCurrency(
                    roomType.nightlyNegotiatedBasePricePence * numberOfNights,
                    false
                  )}
                </TotalSt>
              </PriceWrapperSt>
            </OrderSummaryContainerSt>
            <ReserveButtonSt onClick={() => onClickCallback()}>
              Reserve room
            </ReserveButtonSt>
            {allowPayLater && (
              <PayLaterTextSt>
                Secure now with deposit:{" "}
                <span>
                  {formatPriceWithCurrency(
                    hotelCapsule?.ttoHotelsForGuests.find(
                      (a) => a.hotel.id === hotel.hotel.id
                    )?.depositAmount,
                    false
                  )}
                </span>
              </PayLaterTextSt>
            )}
            {/** Some very complex logic */}
            {roomType.freeCancellationUntil && (
              <ButtonSubTextSt
                color="#008009"
                margin={allowPayLater ? "0" : "1rem 0 0"}
              >
                Free cancellation until{" "}
                {moment(roomType.freeCancellationUntil).format("Do MMMM YYYY")}
              </ButtonSubTextSt>
            )}

            {!roomType.freeCancellationUntil &&
              roomType.roomCancellationText && (
                <ButtonSubTextSt
                  color="#008009"
                  margin={allowPayLater ? "0" : "1rem 0 0"}
                >
                  {roomType.roomCancellationText}
                </ButtonSubTextSt>
              )}

            {roomType.roomOtherInfo && (
              <ButtonSubTextSt color="#9192a2" margin="0.5rem 0 0">
                {roomType.roomOtherInfo}
              </ButtonSubTextSt>
            )}
          </>
        )}
      </ActionsContainerSt>
    </CardSt>
  );
};
