export const LocationIcon = () => {
  return (
    <svg
      width="29"
      height="33"
      viewBox="0 0 29 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1373 18.8752C25.1624 13.5145 25.5604 8.07865 24.1265 5.49549C22.6926 2.91232 18.3764 -1.57568 7.93239 5.92907C-2.51163 13.4338 0.00572794 21.8332 1.58301 24.6749C8.96673 37.9803 33.4057 28.37 28.1373 18.8752Z"
        fill="#EFEFEF"
      />
      <path
        d="M21.1452 13.8096C21.1452 20.0869 14.4993 26.8125 14.4993 26.8125C14.4993 26.8125 7.85352 20.0869 7.85352 13.8096C7.85352 9.60025 10.8292 6.1875 14.4993 6.1875C18.1695 6.1875 21.1452 9.60025 21.1452 13.8096Z"
        fill="#2FDF84"
      />
      <path
        d="M14.4998 17.6459C16.3906 17.6459 17.9234 15.9017 17.9234 13.7501C17.9234 11.5985 16.3906 9.85425 14.4998 9.85425C12.609 9.85425 11.0762 11.5985 11.0762 13.7501C11.0762 15.9017 12.609 17.6459 14.4998 17.6459Z"
        fill="#F3F3F1"
      />
      <path
        d="M9.66602 13.8096C9.66602 9.95317 12.1648 6.77417 15.4056 6.26633C15.1083 6.21958 14.8079 6.1875 14.4993 6.1875C10.8292 6.1875 7.85352 9.60025 7.85352 13.8096C7.85352 20.0869 14.4993 26.8125 14.4993 26.8125C14.4993 26.8125 14.8618 26.4422 15.4056 25.8152C13.5915 23.7224 9.66602 18.6395 9.66602 13.8096Z"
        fill="#00B871"
      />
      <path
        d="M12.8887 13.7501C12.8887 11.958 13.9584 10.4638 15.406 10.0101C15.1152 9.91933 14.8156 9.85425 14.4998 9.85425C12.6091 9.85425 11.0762 11.5987 11.0762 13.7501C11.0762 15.9015 12.6091 17.6459 14.4998 17.6459C14.8156 17.6459 15.1152 17.5817 15.406 17.4901C13.9584 17.0363 12.8887 15.5422 12.8887 13.7501V13.7501Z"
        fill="#D5DBE1"
      />
      <path
        d="M15.998 4.11401L16.0037 3.19735L17.6035 3.21009L17.5979 4.12676L15.998 4.11401Z"
        fill="#A4AFC1"
      />
      <path
        d="M11.3984 4.07788L11.4041 3.16121L13.0039 3.17396L12.9983 4.09062L11.3984 4.07788Z"
        fill="#A4AFC1"
      />
      <path
        d="M14.1738 0.00878906L14.9794 0.00237239L14.9906 1.82196L14.185 1.82837L14.1738 0.00878906Z"
        fill="#A4AFC1"
      />
      <path
        d="M24.6693 10.7707C24.0031 10.7707 23.4609 10.1593 23.4609 9.40855C23.4609 8.6578 24.0031 8.04639 24.6693 8.04639C25.3355 8.04639 25.8776 8.6578 25.8776 9.40855C25.8776 10.1593 25.3355 10.7707 24.6693 10.7707ZM24.6693 8.96214C24.4469 8.96214 24.2665 9.16197 24.2665 9.40764C24.2665 9.6533 24.4469 9.85314 24.6693 9.85314C24.8916 9.85314 25.0721 9.6533 25.0721 9.40764C25.0721 9.16197 24.8916 8.96214 24.6693 8.96214Z"
        fill="#A4AFC1"
      />
      <path
        d="M14.5 27.5C14.3566 27.5 14.2132 27.4423 14.098 27.3268C13.8185 27.0435 7.25 20.3207 7.25 13.8096C7.25 9.22717 10.5028 5.5 14.5 5.5C18.4972 5.5 21.75 9.22717 21.75 13.8096C21.75 20.3207 15.1815 27.0435 14.902 27.3258C14.7868 27.4422 14.6434 27.5 14.5 27.5V27.5ZM14.5 6.875C11.1682 6.875 8.45833 9.98617 8.45833 13.8096C8.45833 18.8311 13.0903 24.3164 14.5 25.8683C15.9097 24.3164 20.5417 18.8302 20.5417 13.8096C20.5417 9.98617 17.8318 6.875 14.5 6.875V6.875Z"
        fill="black"
      />
      <path
        d="M14.5004 18.3334C12.2795 18.3334 10.4727 16.2773 10.4727 13.7501C10.4727 11.2228 12.2795 9.16675 14.5004 9.16675C16.7214 9.16675 18.5282 11.2228 18.5282 13.7501C18.5282 16.2773 16.7214 18.3334 14.5004 18.3334ZM14.5004 10.5417C12.9457 10.5417 11.681 11.9809 11.681 13.7501C11.681 15.5192 12.9457 16.9584 14.5004 16.9584C16.0552 16.9584 17.3199 15.5192 17.3199 13.7501C17.3199 11.9809 16.0552 10.5417 14.5004 10.5417Z"
        fill="black"
      />
    </svg>
  );
};
