import { NonIntegratedHotelForGuestContract } from "@ttoengineering/delphi";
import Image from "next/image";
import styled, { css } from "styled-components";
import { autoMonetizeAffiliateLink } from "../../../../../../utils/cj";
import { LocationIcon } from "../../../../../shared/atoms/Icons/LocationIcon/LocationIcon";

const CardSt = styled.div`
  font-family: Manrope;
  padding: 2rem;
  position: relative;
  margin: 0 -1rem;

  box-shadow: 0px 2px 25px 3px #76739a21;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    padding: 2rem;
    margin: 0 0 0 2rem;
  }`}
`;

const HeaderContainerSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }`}
`;

const HotelTagSt = styled.div`
  background: #e7f3ff;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  height: fit-content;
  width: fit-content;

  p {
    color: #3950d8;
    font-weight: 700;
    font-family: Manrope;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0.5rem 0;
    font-size: 1.25rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 0 1rem;
  }`}
`;

const DistanceToVenueTagSt = styled.div`
  // background: #e7ffec;
  border-radius: 4px;
  padding: 0.25rem 1rem;
  height: fit-content;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: fit-content;
  width: fit-content;

  p {
    color: #398d5a;
    font-weight: 700;
    font-family: Manrope;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    margin: 0.5rem 0 0.5rem 0.5rem;
    font-size: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 0 1rem;
    margin-top: 0;
  }`}
`;

const HotelDescriptionSt = styled.p`
  font-family: Manrope;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  color: #2a2b3f;
`;

const ImagesContainerSt = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2rem;

  ${({ theme }) => `${theme.breakpoints.md} {
    grid-template-columns: repeat(4, 1fr);
  }`}
`;

const ImageWrapperSt = styled.div`
  position: relative;
  height: 110px;
  width: 100%;
`;

const ReserveButtonSt = styled.button`
  width: 100%;
  background-color: #2a2b3f;
  color: white;
  padding: 0.9rem;
  font-weight: 600;
  font-size: 1rem;
  font-family: Manrope;
  -webkit-font-smoothing: antialiased;

  :hover {
    cursor: pointer;
    background-color: #161621;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    width: fit-content;
  }`}
`;

const ButtonContainerSt = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
`;

export const ExternalHotelListCard = ({
  hotel,
  slug,
}: {
  hotel: NonIntegratedHotelForGuestContract;
  slug: string;
}) => {
  return (
    <CardSt>
      <HeaderContainerSt>
        <HotelTagSt>
          <p>{hotel.nonintegrated_hotel.name}</p>
        </HotelTagSt>
        <DistanceToVenueTagSt>
          <LocationIcon />
          <p>{hotel.distanceFromVenueTag}</p>
        </DistanceToVenueTagSt>
      </HeaderContainerSt>

      <HotelDescriptionSt>
        {hotel.nonintegrated_hotel.description}
      </HotelDescriptionSt>

      <ImagesContainerSt>
        {hotel.nonintegrated_hotel.mainImage?.url && (
          <ImageWrapperSt>
            <Image
              layout="fill"
              objectFit="cover"
              src={hotel.nonintegrated_hotel.mainImage?.url}
              alt="Hotel image"
            />
          </ImageWrapperSt>
        )}
        {hotel.nonintegrated_hotel.images?.slice(0, 3).map((img) =>
          img.url ? (
            <ImageWrapperSt key={img.id}>
              <Image
                layout="fill"
                objectFit="cover"
                src={img?.url}
                alt="Hotel image"
              />
            </ImageWrapperSt>
          ) : (
            <></>
          )
        )}
      </ImagesContainerSt>

      <ButtonContainerSt>
        <ReserveButtonSt
          onClick={() =>
            window.open(
              autoMonetizeAffiliateLink(
                hotel.nonintegrated_hotel.url,
                `${slug},${hotel.nonintegrated_hotel.id}`
              )
            )
          }
        >
          {hotel.nightlyBasePriceText
            ? hotel.nightlyBasePriceText
            : "Check rates"}
        </ReserveButtonSt>
      </ButtonContainerSt>
    </CardSt>
  );
};
