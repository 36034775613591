import {
  HotelCapsuleContract,
  HotelForGuestContract,
  RoomContract,
  RoomTypeContract,
  WeddingHotelWithAvailabilityAndRatesContract,
} from "@ttoengineering/delphi";
import Image from "next/image";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import {
  AmplitudeEventType,
  useAmplitudeContext,
} from "../../../../../../contexts/AmplitudeContext";
import { UISpacer } from "../../../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { RoomRateCard } from "../RoomRateCard/RoomRateCard";
import { Markdown } from "../../../../../shared/atoms/Markdown/Markdown";
import { HotelListCard } from "../HotelListCard/HotelListCard";
import { dateInPast } from "../../../../../../utils/dates";
import { ExternalHotelListCard } from "../ExternalHotelListCard/ExternalHotelListCard";
import { useCurrencyContext } from "../../../../../../contexts/CurrencyContext";
import ReactSelect from "react-select";
import { useResponsive } from "../../../../../../utils/hooks/useResponsive";
import { theme } from "../../../../../../utils/theme";
import { openHubspotChat } from "../../../../../../utils/hubspot";

const WrapperSt = styled.div`
  padding: 0rem 0rem;
  background: #eef3fc;
  min-height: 100vh;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    padding: 3rem 2rem;
  }`}

  * {
    font-family: Manrope;
  }
`;

const ContainerSt = styled.div`
  max-width: 90rem;
  margin: 0 auto;
  background: white;
`;

const HeaderContainerSt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0rem;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    padding: 4rem;
    padding-bottom: 0rem;
    padding-top: 2rem;
    flex-direction: row;
  }`}

  > div:first-of-type {
    padding: 2rem;

    ${({ theme }) => `${theme.breakpoints.mlg} {
      margin-right: 4rem;
      padding: 0rem;
      max-width: 35rem;
    }`}
  }
`;

interface TextStProps {
  color?: string;
  textAlign?: string;
  margin?: string;
}

const HeadingH1St = styled.h1`
  font-family: Manrope;
  font-weight: 600;
  font-size: 2.28rem;
  line-height: 3.07rem;
  color: #2a2b3f;
`;

const RoomBlockExpiredContentSt = styled.div`
  padding-bottom: 2rem;
  font-family: Manrope;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    padding-top: 1.5rem;
  }`}

  h2 {
    font-size: 1.71rem;
    font-weight: 600;
    line-height: 2.35rem;
    color: #2a2b3f;
  }

  button {
    background-color: #425cf0;
    padding: 0.75rem 1rem;
    color: white;
    -webkit-font-smoothing: antialiased;

    :hover {
      background-color: #3950d8;
      cursor: pointer;
    }
  }

  p {
    font-size: 1.07rem;
    font-weight: 500;
    line-height: 1.64rem;
    color: #7e7f93;
  }
`;

const MarkdownSt = styled(Markdown)`
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  color: #9192a2;
`;

const ParaSt = styled.p<TextStProps>`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;
  line-height: 150%;
  color: ${({ color }) => (color ? color : "#9192a2")};
  float: revert;
  ${({ theme, textAlign }) => `${theme.breakpoints.mlg} {
    flex-direction: row;
    ${textAlign ? `text-align: ${textAlign}` : ``}
  }`}
  margin: ${({ margin }) => (margin ? margin : "")};
`;

const ContentContainerSt = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    flex-direction: row;
  }`}
`;

const LeftInfoSt = styled.div`
  flex-shrink: 0;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    margin-right: 4rem;
    align-self: flex-start;
    position: sticky;
    top: 4rem;
    max-width: 20rem;
  }`}
`;

const CurrencySelectContainerSt = styled.div`
  display: none;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    display: block;
  }`}
`;

const HRSt = styled.hr`
  margin: 1.5rem 0;
  border-top: 1px solid #9192a248;
  border-bottom: 0;
`;

const HeadingH3St = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 33px;

  color: #2a2b3f;
`;

const HotelSpanSt = styled.span`
  color: #3950d8;
`;

const ImageContainerSt = styled.div`
  position: relative;
  flex-grow: 1;
  height: 22rem;
  width: 100%;
  max-width: 580px;
`;

const SelectContainerSt = styled.div`
  /* width: 160px; */

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 100px;
  }`}
`;

const PageHeaderSt = styled.div`
  display: flex;
  justify-content: space-between;

  ${SelectContainerSt} {
    display: block;

    ${({ theme }) => `${theme.breakpoints.mlg} {
      display: none;
    }`}
  }
`;

const WeddingPlannerLogoContainerSt = styled.div`
  position: relative;
  /* flex-grow: 1; */
  height: 3.25rem;
  width: 40%;
  max-width: 17rem;
`;

const BottomContainerSt = styled.div`
  padding: 2rem;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    padding: 2rem 4rem;
  }`}
`;

const ArrowContainerSt = styled.div`
  text-align: center;
`;
const ConciergeCardSt = styled.div`
  background: #f6f8fc;
  margin: 4rem -2rem;
  padding: 2rem 2rem 2rem 3.85rem;

  h4 {
    font-weight: 600;
    font-size: 1.14rem;
    line-height: 22px;
    color: #2a2b3f;
  }

  ${({ theme }) => `${theme.breakpoints.mlg} {
    margin: 4rem 0 0 1.85rem;
    display: flex;

    h4 {
      margin: 0;
    }
  }`}
`;

const ConciergeContentSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.mlg} {
    margin-left: 2rem;
  }`}
`;

const ImageCircleSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.mlg} {
    margin-top: 2rem;
  }`}
`;

const ButtonGroupSt = styled.div`
  margin-top: 2rem;

  button {
    background-color: #425cf0;
    color: white;
    width: 100%;
    padding: 0.85rem;
    -webkit-font-smoothing: antialiased;

    :hover {
      background-color: #3950d8;
      cursor: pointer;
    }
  }

  ${({ theme }) => `${theme.breakpoints.mlg} {
    display: flex;

    button {
      max-width: 11.2rem;
      // height: 3.07rem;
    }

    p {
      margin: 0 0 0 2rem;
    }
  }`}
`;

const OtherOptionsBorderSt = styled.div`
  font-family: Manrope;
  padding: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;

  margin: 0 -1rem;
  text-align: center;
  color: #2a2b3f;

  ${({ theme }) => `${theme.breakpoints.mlg} {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      max-width: 24rem;
    }

    > span {
      white-space: nowrap;
    }
  }`}
`;

const ShowMoreButtonSt = styled.button`
  font-weight: 500;
  font-size: 1.07rem;
  line-height: 1.42rem;
  text-decoration-line: underline;
  color: #9192a2;
  margin: 0 1.85rem 4rem;

  :hover {
    cursor: pointer;
  }
`;

const RoomTypeRatesSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.mlg} {
    margin-left: 4rem;
  }`}
`;

const RoomTypeRates = ({
  rooms,
  roomType,
  nightsAway,
  hotel,
  hotelCapsule,
  startingNumber,
}: {
  rooms: RoomContractWithSold[];
  roomType: RoomTypeContract;
  nightsAway: number;
  hotel: WeddingHotelWithAvailabilityAndRatesContract;
  hotelCapsule: HotelCapsuleContract;
  startingNumber: number;
}) => {
  const [showAllRates, setShowAllRates] = useState(false);

  return (
    <RoomTypeRatesSt>
      {!showAllRates && (
        <div>
          {rooms.slice(0, 3).map((room, roomIndex) => (
            <Fragment key={room.id}>
              <RoomRateCard
                isSoldOut={!!room.sold}
                room={room}
                roomType={roomType}
                number={startingNumber + roomIndex + 1}
                numberOfNights={nightsAway}
                hotel={hotel}
                hotelCapsule={hotelCapsule}
              />
              <UISpacer variant="sm" />
            </Fragment>
          ))}
          {rooms.length > 3 && (
            <ShowMoreButtonSt onClick={() => setShowAllRates(true)}>{`${
              rooms.length - 3
            } more of this room type...`}</ShowMoreButtonSt>
          )}
        </div>
      )}
      {showAllRates &&
        rooms.map((room, roomIndex) => (
          <Fragment key={room.id}>
            <RoomRateCard
              isSoldOut={!!room.sold}
              room={room}
              roomType={roomType}
              number={startingNumber + roomIndex + 1}
              numberOfNights={nightsAway}
              hotel={hotel}
              hotelCapsule={hotelCapsule}
            />
            <UISpacer variant="sm" />
          </Fragment>
        ))}
    </RoomTypeRatesSt>
  );
};

interface RoomContractWithSold extends RoomContract {
  sold?: boolean;
}

const RoomBookingPage = () => {
  const { query } = useRouter();
  const [hotelCapsule, setHotelCapsule] = useState<HotelCapsuleContract>();
  const [isFirstLoad, setIsFirstLoaded] = useState(true);
  const [nightsAway, setNightsAway] = useState<number | undefined>();
  const { amplitudeLogEvent, amplitudeClient } = useAmplitudeContext();
  const [hotels, setHotels] =
    useState<WeddingHotelWithAvailabilityAndRatesContract[]>();

  const { exchangeRates, selectedCurrency, setCurrency } = useCurrencyContext();

  const fxOptions = exchangeRates?.map((er) => ({
    value: er.currencyCode,
    label: er.currencyCode,
  }));

  const { isTablet } = useResponsive();

  const getDaysAway = (end: string, start?: string) => {
    const now = start ? new Date(start) : (new Date() as any);
    const date2 = new Date(end) as any;
    const diffTime = Math.abs(date2 - now);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const formatHotelNames = (hotelsForGuests: HotelForGuestContract[]) => {
    return hotelsForGuests.map((hotelForGuest, i) => {
      if (hotelsForGuests.length === 1) {
        return hotelForGuest.hotel.name;
      } else if (hotelsForGuests.length - 1 === i) {
        return `and ${hotelForGuest.hotel.name} `;
      } else {
        return `${hotelForGuest.hotel.name}, `;
      }
    });
  };

  useEffect(() => {
    if (isFirstLoad && hotelCapsule && amplitudeLogEvent && amplitudeClient) {
      amplitudeLogEvent(AmplitudeEventType.HOTEL_CAPSULE_V2_LOADED_PAGE, {
        template: "Hotel Only Page V2",
        slug: hotelCapsule?.slug,
      });
      setIsFirstLoaded(false);
    }
  }, [isFirstLoad, hotelCapsule, amplitudeLogEvent, amplitudeClient]);

  useEffect(() => {
    if (hotelCapsule?.endDate && hotelCapsule?.startDate) {
      setNightsAway(
        getDaysAway(hotelCapsule?.endDate, hotelCapsule?.startDate)
      );
    }
  }, [hotelCapsule, setNightsAway]);

  useEffect(() => {
    const fetchHotelCapsule = async () => {
      const hotelCapsuleResponse = await fetch(
        `${
          process.env.NEXT_PUBLIC_STRAPI_URL
        }/hotel-capsules/${encodeURIComponent(query.slug as string)}`
      );

      const jsonHotelCapsule = await hotelCapsuleResponse.json();

      setHotelCapsule(jsonHotelCapsule);
    };

    if (query?.slug) {
      fetchHotelCapsule();
    }
  }, [query]);

  useEffect(() => {
    if (hotelCapsule?.startDate && hotelCapsule?.endDate) {
      const ids = hotelCapsule.ttoHotelsForGuests
        .map((h) => h.hotel.id)
        .join(",");

      const fetchHotels = async () => {
        const hotelsResponse = await fetch(
          `${process.env.NEXT_PUBLIC_STRAPI_URL}/hotels/availability?hotelIds=${ids}&startDate=${hotelCapsule.startDate}&endDate=${hotelCapsule.endDate}`
        );
        const data = await hotelsResponse.json();

        const hotelsForGuests = data.map((h: any) => {
          const correspondingHotelForGuest =
            hotelCapsule.ttoHotelsForGuests.find(
              (hfg) => hfg.hotel.id === parseInt(h.hotelId)
            );

          return {
            ...correspondingHotelForGuest,
            availableRooms: h.availableRooms,
            roomTypes: h.roomTypes,
            soldRooms: h.soldRooms,
          };
        });

        setHotels(hotelsForGuests);
      };

      if (ids) fetchHotels();
    }
  }, [hotelCapsule]);

  const hasRoomBlockExpired = hotelCapsule?.reservationDeadlineDate
    ? dateInPast(new Date(hotelCapsule?.reservationDeadlineDate), new Date())
    : false;

  return (
    <>
      {hotelCapsule?.version === "V2" && (
        <WrapperSt>
          <ContainerSt>
            <HeaderContainerSt>
              <div>
                <PageHeaderSt>
                  <WeddingPlannerLogoContainerSt>
                    <Image
                      src={`${
                        hotelCapsule?.weddingPlannerLogo?.url
                          ? hotelCapsule?.weddingPlannerLogo?.url
                          : "/images/electra_img.png"
                      }`}
                      alt="hotel image"
                      layout="fill"
                      objectFit="cover"
                    ></Image>
                  </WeddingPlannerLogoContainerSt>
                  <SelectContainerSt>
                    <ReactSelect
                      styles={{
                        option: (provided: any) => ({
                          ...provided,
                          fontSize: 16,
                        }),
                        menuList: (provided: any, state: any) => ({
                          ...provided,
                          maxHeight: isTablet ? "300px" : "unset",
                          paddingBottom: isTablet ? "4px" : "unset",
                          paddingTop: isTablet ? "4px" : "unset",
                          position: isTablet ? "relative" : "fixed",
                          backgroundColor: theme.colors.white,
                          ...(isTablet
                            ? {}
                            : {
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                height: "100%",
                              }),
                        }),
                      }}
                      options={fxOptions}
                      value={{
                        value: selectedCurrency,
                        label: selectedCurrency,
                      }}
                      onChange={(value: any) => {
                        setCurrency(value.value);
                      }}
                      isSearchable={false}
                    />
                  </SelectContainerSt>
                </PageHeaderSt>
                <HeadingH1St>
                  {hotelCapsule.title
                    ? hotelCapsule.title
                    : `Your accommodation for the wedding of
                  ${hotelCapsule?.coupleNames}`}
                </HeadingH1St>
                {hasRoomBlockExpired ? (
                  <RoomBlockExpiredContentSt>
                    {/* <UISpacer /> */}
                    <h2>
                      The room block for this wedding has concluded, but we can
                      still help you find a great hotel:
                    </h2>
                    <UISpacer variant="sm" />
                    <button
                      onClick={() => {
                        hotelCapsule?.customBookingRequestFormUrl
                          ? window.open(
                              hotelCapsule?.customBookingRequestFormUrl
                            )
                          : openHubspotChat();
                      }}
                    >
                      Create booking request
                    </button>
                    <UISpacer variant="sm" />
                    <p>
                      To arrange accommodation for this wedding, please complete
                      a custom booking request and we’ll help find you suitable
                      accommodation with great rates. If you have a question,
                      feel free to email us (nina@thatstheone.io)
                    </p>
                  </RoomBlockExpiredContentSt>
                ) : (
                  <MarkdownSt>
                    {hotelCapsule?.accommodationPara || ""}
                  </MarkdownSt>
                )}
              </div>
              <ImageContainerSt>
                <Image
                  src={`${
                    hotelCapsule?.mainImage?.url
                      ? hotelCapsule?.mainImage?.url
                      : "/images/electra_img.png"
                  }`}
                  alt="hotel image"
                  layout="fill"
                  objectFit="cover"
                  priority
                ></Image>
              </ImageContainerSt>
            </HeaderContainerSt>
            {!hasRoomBlockExpired && (
              <BottomContainerSt>
                <ContentContainerSt>
                  <LeftInfoSt>
                    <HeadingH3St>Please choose your room ...</HeadingH3St>
                    <MarkdownSt>
                      {hotelCapsule?.chooseRoomPara || ""}
                    </MarkdownSt>
                    {hotelCapsule?.ttoHotelsForGuests.length > 0 && (
                      <>
                        <ArrowContainerSt>
                          <Image
                            src="/images/arrow-down.svg"
                            alt="arrow-down"
                            height="20"
                            width="20"
                          />
                        </ArrowContainerSt>
                        <ParaSt color="#FD7662">
                          Reserve your room by{" "}
                          {moment(hotelCapsule?.reservationDeadlineDate).format(
                            "dddd DD MMMM"
                          )}{" "}
                          (in{" "}
                          {hotelCapsule?.reservationDeadlineDate &&
                            getDaysAway(
                              hotelCapsule?.reservationDeadlineDate
                            )}{" "}
                          days)
                        </ParaSt>
                        <ParaSt>
                          We’ve reserved rooms at{" "}
                          <HotelSpanSt>
                            {hotelCapsule?.ttoHotelsForGuests &&
                              formatHotelNames(
                                hotelCapsule?.ttoHotelsForGuests
                              )}{" "}
                          </HotelSpanSt>
                          for arrival on{" "}
                          {moment(hotelCapsule?.startDate).format(
                            "dddd DD MMMM"
                          )}{" "}
                          and farewell on{" "}
                          {moment(hotelCapsule?.endDate).format("dddd DD MMMM")}{" "}
                          ({nightsAway} nights)
                        </ParaSt>
                        {!hotelCapsule.hideConciergeSupport && (
                          <ParaSt color="#3950D8">
                            If you want to request different dates or rooms,
                            please email: nina@thatstheone.io
                          </ParaSt>
                        )}
                      </>
                    )}
                    <CurrencySelectContainerSt>
                      <HRSt />
                      <ParaSt>Change currency:</ParaSt>
                      <SelectContainerSt>
                        <ReactSelect
                          styles={{
                            option: (provided: any) => ({
                              ...provided,
                              fontSize: 16,
                            }),
                            menuList: (provided: any, state: any) => ({
                              ...provided,
                              maxHeight: isTablet ? "300px" : "unset",
                              paddingBottom: isTablet ? "4px" : "unset",
                              paddingTop: isTablet ? "4px" : "unset",
                              position: isTablet ? "relative" : "fixed",
                              backgroundColor: theme.colors.white,
                              ...(isTablet
                                ? {}
                                : {
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    height: "100%",
                                  }),
                            }),
                          }}
                          options={fxOptions}
                          value={{
                            value: selectedCurrency,
                            label: selectedCurrency,
                          }}
                          onChange={(value: any) => {
                            setCurrency(value.value);
                          }}
                          isSearchable={false}
                          menuPlacement="auto"
                        />
                      </SelectContainerSt>
                    </CurrencySelectContainerSt>
                  </LeftInfoSt>
                  <div>
                    <ParaSt color="#398D5A" textAlign="right">
                      {hotelCapsule?.roomListDetail}
                    </ParaSt>
                    <UISpacer variant="sm" />
                    <div>
                      {nightsAway &&
                        hotels?.map((hotel) => {
                          let prevRoomTypeLengths = [] as number[];

                          return (
                            <>
                              <HotelListCard hotel={hotel} />
                              <UISpacer variant="sm" />

                              {hotel.roomTypes?.map((rt, rtIndex) => {
                                const availableRooms =
                                  hotel?.availableRooms.filter(
                                    (ar) => ar.hotelRoomType === rt.id
                                  );
                                const soldRooms = hotel?.soldRooms.filter(
                                  (ar) => ar.hotelRoomType === rt.id
                                );
                                const allRooms = [
                                  ...availableRooms,
                                  ...soldRooms.map((sr) => ({
                                    ...sr,
                                    sold: true,
                                  })),
                                ].sort(
                                  (a, b) => a.id - b.id
                                ) as RoomContractWithSold[];

                                prevRoomTypeLengths.push(allRooms.length);

                                const startingNumber = prevRoomTypeLengths
                                  .slice(0, rtIndex)
                                  .reduce((partialSum, a) => partialSum + a, 0);

                                return (
                                  <RoomTypeRates
                                    key={rt.id}
                                    rooms={allRooms}
                                    roomType={rt}
                                    nightsAway={nightsAway}
                                    hotel={hotel}
                                    hotelCapsule={hotelCapsule}
                                    startingNumber={startingNumber}
                                  />
                                );
                              })}
                            </>
                          );
                        })}
                    </div>
                    <div>
                      {hotelCapsule?.ttoHotelsForGuests.length > 0 &&
                        hotelCapsule?.nonIntegratedHotelsForGuests?.length >
                          0 && (
                          <OtherOptionsBorderSt>
                            <p>
                              {`Prefer to book outside of the rooms we've blocked? Here are some other recommendations:`}
                            </p>
                          </OtherOptionsBorderSt>
                        )}
                      {hotelCapsule?.nonIntegratedHotelsForGuests.map(
                        (hotel) => (
                          <div key={hotel.id}>
                            <ExternalHotelListCard
                              hotel={hotel}
                              slug={hotelCapsule.slug}
                            />
                            <UISpacer variant="sm" />
                          </div>
                        )
                      )}
                    </div>
                    {!hotelCapsule.hideConciergeSupport &&
                      hotelCapsule?.ttoHotelsForGuests.length > 0 && (
                        <ConciergeCardSt>
                          <ImageCircleSt>
                            <Image
                              src="/images/concierge_img.png"
                              alt="concierge image"
                              height="80"
                              width="80"
                              layout="fixed"
                            ></Image>
                          </ImageCircleSt>
                          <ConciergeContentSt>
                            <h4>Have a specific accommodation request?</h4>
                            <ParaSt>
                              Our guest concierge can help you with specific
                              room requests or booking modifications with access
                              to exclusive rates — just let them know
                            </ParaSt>
                            <ButtonGroupSt>
                              <button onClick={openHubspotChat}>
                                Send us a message
                              </button>
                              <div>
                                <ParaSt margin="1.5rem 0 0">
                                  or email guest concierge
                                </ParaSt>
                                <ParaSt margin="0" color="#2A2B3F">
                                  nina@thatstheone.io
                                </ParaSt>
                              </div>
                            </ButtonGroupSt>
                          </ConciergeContentSt>
                        </ConciergeCardSt>
                      )}
                  </div>
                </ContentContainerSt>
              </BottomContainerSt>
            )}
          </ContainerSt>
        </WrapperSt>
      )}
    </>
  );
};

export default RoomBookingPage;
