import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Head from "next/head";
import { useRouter } from "next/router";
import Select from "react-select";
import { useCurrencyContext } from "../../../contexts/CurrencyContext";
import { useResponsive } from "../../../utils/hooks/useResponsive";
import { theme } from "../../../utils/theme";
import { UITypography } from "../../../components/shared/atoms/UIComponents/UITypography/UITypography";
import { UISpacer } from "../../../components/shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import moment from "moment";
import GuestPageHotelCard from "../../../components/modules/guestPage/GuestPageHotelCard/GuestPageHotelCard";
import GuestPageNonintegratedHotelCard from "../../../components/modules/guestPage/GuestPageNonIntegratedHotelCard/GuestPageNonIntegratedHotelCard";
import {
  HotelCapsuleContract,
  WeddingHotelWithAvailabilityAndRatesContract,
} from "@ttoengineering/delphi";
import RoomBookingPage from "../../../components/modules/hotelPage/direct/v2/RoomBookingPage/RoomBookingPage";
import { useSegment } from "../../../contexts/SegmentContext";

const SelectContainerSt = styled.div`
  width: 160px;

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 100px;
  }`}
`;

const FooterSt = styled.div`
  padding: 1rem 1rem 4rem;
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => `${theme.breakpoints.md} {
    ${SelectContainerSt} {
      display: none;
    }
  }`}
`;

const HeaderSt = styled.header`
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  margin: 0 auto 2rem;
  border-bottom: 1px solid #f1f1f1;

  img {
    margin: 0 auto;
  }

  ${SelectContainerSt} {
    display: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    img {
      margin: unset;
    }

    ${SelectContainerSt} {
      display: block;
    }
  }`}
`;

const HeroContainerSt = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatsBarSt = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${({ theme }) => `${theme.breakpoints.md} {
    justify-content: unset;
  }`}
`;

const StatSt = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 1.25rem 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.text01};
  font-size: 0.7125rem;
  justify-content: center;

  img {
    margin-right: 0.75rem;
    width: 1.5rem;
  }
`;

const HrSt = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.ui02};
  width: 100%;
  opacity: 0.8;
`;

const HotelsContainerSt = styled.div`
  padding: 1rem;
  margin: 0 auto;
  max-width: 900px;
`;

const HotelOnlyPage = () => {
  const { query } = useRouter();
  const [hotelCapsule, setHotelCapsule] = useState<HotelCapsuleContract>();

  const [hotels, setHotels] =
    useState<WeddingHotelWithAvailabilityAndRatesContract[]>();

  const { isTablet } = useResponsive();
  const { exchangeRates, selectedCurrency, setCurrency } = useCurrencyContext();
  const segment = useSegment();

  const slug = hotelCapsule?.slug

  useEffect(() => {
    if (!slug) return

    segment.track('RBP Viewed', {
      slug,
    });
  }, [segment, slug])

  const fxOptions = exchangeRates?.map((er) => ({
    value: er.currencyCode,
    label: er.currencyCode,
  }));

  useEffect(() => {
    const fetchHotelCapsule = async () => {
      const hotelCapsuleResponse = await fetch(
        `${
          process.env.NEXT_PUBLIC_STRAPI_URL
        }/hotel-capsules/${encodeURIComponent(query.slug as string)}`
      );

      const jsonHotelCapsule = await hotelCapsuleResponse.json();

      setHotelCapsule(jsonHotelCapsule);
    };

    if (query?.slug) {
      fetchHotelCapsule();
    }
  }, [query]);

  useEffect(() => {
    if (hotelCapsule?.startDate && hotelCapsule?.endDate) {
      const ids = hotelCapsule.ttoHotelsForGuests
        .map((h) => h.hotel.id)
        .join(",");

      const fetchHotels = async () => {
        const hotelsResponse = await fetch(
          `${process.env.NEXT_PUBLIC_STRAPI_URL}/hotels/availability?hotelIds=${ids}&startDate=${hotelCapsule.startDate}&endDate=${hotelCapsule.endDate}`
        );
        const data = await hotelsResponse.json();

        const hotelsForGuests = data.map((h: any) => {
          const correspondingHotelForGuest =
            hotelCapsule.ttoHotelsForGuests.find(
              (hfg) => hfg.hotel.id === parseInt(h.hotelId)
            );

          return {
            ...correspondingHotelForGuest,
            availableRooms: h.availableRooms,
            roomTypes: h.roomTypes,
          };
        });

        setHotels(hotelsForGuests);
      };

      if (ids) fetchHotels();
    }
  }, [hotelCapsule]);

  return (
    <>
      <Head>
        <title>{hotelCapsule?.coupleNames}</title>
        <meta
          name="description"
          content={`Book soon to avoid disappointment`}
        ></meta>
        <meta name="robots" content="noindex"></meta>
        <meta
          property="og:title"
          content={`Recommended hotels for our event! 🏰`}
        />
        <meta
          property="og:description"
          content={`Book soon to avoid disappointment`}
        />
      </Head>

      {hotelCapsule?.version === "V2" ? (
        <RoomBookingPage />
      ) : (
        hotelCapsule && (
          <>
            <div>
              <HeaderSt>
                <img src="https://res.cloudinary.com/tto/image/upload/v1634629778/staticImages/THAT_S_THE_ONE_k92gsn.svg" />
                <SelectContainerSt>
                  <Select
                    styles={{
                      option: (provided: any) => ({
                        ...provided,
                        fontSize: 16,
                      }),
                      menuList: (provided: any, state: any) => ({
                        ...provided,
                        maxHeight: isTablet ? "300px" : "unset",
                        paddingBottom: isTablet ? "4px" : "unset",
                        paddingTop: isTablet ? "4px" : "unset",
                        position: isTablet ? "relative" : "fixed",
                        backgroundColor: theme.colors.white,
                        ...(isTablet
                          ? {}
                          : {
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              height: "100%",
                            }),
                      }),
                    }}
                    options={fxOptions}
                    value={{ value: selectedCurrency, label: selectedCurrency }}
                    onChange={(value: any) => {
                      setCurrency(value.value);
                    }}
                    isSearchable={false}
                  />
                </SelectContainerSt>
              </HeaderSt>

              <HeroContainerSt>
                <UITypography variant="h3" lineHeight="2rem">
                  {hotelCapsule?.coupleNameSupertitle ||
                    "Hotel rates for the guests of"}
                </UITypography>
                <UITypography variant="h2" fontWeight={600} lineHeight="2.5rem">
                  {hotelCapsule?.coupleNames}
                </UITypography>
                <UISpacer variant="sm" />
                <StatsBarSt>
                  <StatSt>
                    <img
                      src="https://res.cloudinary.com/tto/image/upload/v1625483451/staticIcons/noun_Suitcase_3605619_rkhipu.svg"
                      alt="icon"
                    />
                    <p>{hotelCapsule?.venueName}</p>
                  </StatSt>
                  <StatSt>
                    <img
                      src="https://res.cloudinary.com/tto/image/upload/v1625483451/staticIcons/noun_Bed_3605596_sll5bl.svg"
                      alt="icon"
                    />
                    <p>
                      {`${moment(hotelCapsule?.startDate).format(
                        "D MMMM"
                      )} - ${moment(hotelCapsule?.endDate).format("D MMMM")}`}
                    </p>
                  </StatSt>
                </StatsBarSt>
              </HeroContainerSt>
            </div>

            <UISpacer />

            <HotelsContainerSt>
              {hotels?.map((hotel) => (
                <div key={hotel.id}>
                  <GuestPageHotelCard
                    isOnHotelCapsule
                    hotelListing={hotel}
                    customTheme={null}
                    weddingStartDate={hotelCapsule?.startDate || ""}
                    weddingEndDate={hotelCapsule?.endDate || ""}
                  />
                  <UISpacer variant="sm" />
                  <HrSt />
                  <UISpacer variant="sm" />
                </div>
              ))}

              {hotelCapsule?.nonIntegratedHotelsForGuests.map((hotel) => (
                <div key={hotel.id}>
                  <GuestPageNonintegratedHotelCard
                    hotelListing={hotel}
                    customTheme={null}
                  />
                  <UISpacer variant="sm" />
                  <HrSt />
                  <UISpacer variant="sm" />
                </div>
              ))}
            </HotelsContainerSt>
            <FooterSt>
              <SelectContainerSt>
                <Select
                  styles={{
                    option: (provided: any) => ({
                      ...provided,
                      fontSize: 16,
                    }),
                    menuList: (provided: any, state: any) => ({
                      ...provided,
                      maxHeight: isTablet ? "300px" : "unset",
                      paddingBottom: isTablet ? "4px" : "unset",
                      paddingTop: isTablet ? "4px" : "unset",
                      position: isTablet ? "relative" : "fixed",
                      backgroundColor: theme.colors.white,
                      ...(isTablet
                        ? {}
                        : {
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            height: "100%",
                          }),
                    }),
                  }}
                  options={fxOptions}
                  value={{ value: selectedCurrency, label: selectedCurrency }}
                  onChange={(value: any) => {
                    setCurrency(value.value);
                  }}
                  isSearchable={false}
                />
              </SelectContainerSt>
            </FooterSt>
          </>
        )
      )}
    </>
  );
};

export default HotelOnlyPage;
